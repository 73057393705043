import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, PLATFORM_ID, signal, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import pack from '../../package.json';
import { ToastService } from './shared/services/toast.service';
import { AuthService } from './shared/services/auth.service';
import { faHeadset, faLock, faPaperPlane, faTurnLeft, faWifiExclamation } from '@fortawesome/pro-regular-svg-icons';
import { SuperviseDataService } from './shared/services/supervise/supervise-data.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, isPlatformServer } from '@angular/common';
import { CheckUpdateService } from './shared/services/update/check-update.service';
import { Loader } from '@googlemaps/js-api-loader';
import { AudioService } from './shared/services/audio/audio.service';

@Component({
  imports: [AsyncPipe, FaIconComponent, RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit {
  @ViewChild('localAudio') localAudio!: ElementRef;
  @ViewChild('remoteAudio') remoteAudio!: ElementRef;

  @ViewChild('routerOutlet') routerOutlet!: RouterOutlet;

  title = 'enrollhere-dialer';

  faLock = faLock
  faPaperPlane = faPaperPlane
  faTurnLeft = faTurnLeft
  faWifiExclamation = faWifiExclamation
  faHeadset = faHeadset

  isOffline = signal(false)

  version = pack.version;

  //do not remove checkUpdateService as it then stops to update automatically
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public audioService: AudioService,
    public authService: AuthService,
    private ngZone: NgZone,
    private superviseService: SuperviseDataService,
    private toastService: ToastService,
    public updateService: CheckUpdateService,
  ) { }

  ngAfterViewInit() {
    this.loadMaps();
    this.authService.init()
    this.audioService.initializeAudio()

    this.superviseService.localAudio = this.localAudio
    this.superviseService.remoteAudio = this.remoteAudio

    console.log('localAudio Call Status', this.localAudio)
    console.log('remoteAudio Call Status', this.remoteAudio)

    // Check query params again and initialize supervise service if needed
    const queryParams = this.parseQueryParams(window.location.href);
    if (queryParams['hm'] !== 'true') {
      console.log('Initializing supervise service');
      this.superviseService.initialize();
    }


    this.ngZone.runOutsideAngular(() => {

      window.addEventListener("online", async (e) => {
        console.log("online");
        if (this.isOffline()) {
          this.toastService.success("Internet connection recovered 😍");
        }
        this.isOffline.set(false)
      });
      window.addEventListener("offline", async (e) => {
        console.log("offline");
        this.toastService.error(
          "Internet connection lost 😢",
          "Please connect to the internet to continue"
        );

        this.isOffline.set(true)
      });

      window.addEventListener("unload", async (e) => {
        console.log("unload");
      })

      // window.addEventListener("pagehide", async (e) => {
      //   console.log("pagehide");
      // })

      // window.addEventListener("pageshow", async (e) => {
      //   console.log("pageshow");
      // })

      window.addEventListener("freeze", async (e) => {
        console.log("freeze");
      })

      window.addEventListener("resume", async (e) => {
        console.log("resume");
      })
      // window.addEventListener("visibilitychange", async (e) => {
      //   console.log("visibilitychange", document.visibilityState);
      // })

    })
  }


  loadMaps() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const loader = new Loader({
      apiKey: environment.googleMapsApiKey,
      version: 'weekly',
    });

    loader.importLibrary('core').then(async () => {
      console.log('google maps loaded');
    });
  }

  moveHere() {
    this.authService.moveSessionHere()
  }

  backToEH() {
    window.location.href = environment.enrollhereBaseUrl
  }

  private parseQueryParams(url: string): { [key: string]: string } {
    const queryParams: { [key: string]: string } = {};
    const queryString = url.split('?')[1];
    if (queryString) {
      const pairs = queryString.split('&');
      for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
      }
    }
    return queryParams;
  }

}
