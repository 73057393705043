import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './shared/services/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: environment.isDemo
      ? () => import('./shared/theme/theme-demo-border/theme-demo-border.component').then(m => m.ThemeDemoBorderComponent)
      : !environment.production
        ? () => import('./shared/theme/theme-dev/theme-dev.component').then(m => m.ThemeDevComponent)
        : undefined,
    children: [
      {
        path: 'sso',
        loadChildren: () =>
          import('./components/auth/sso/sso.routes').then((m) => m.ssoRoutes),
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadComponent: () => import('./shared/theme/theme-sidebar/theme-sidebar.component').then(m => m.ThemeSidebarComponent),
        children: [
          {
            path: '',
            loadChildren: () =>
              import('./components/dashboard/dashboard.routes').then(
                (m) => m.dashboardRoutes,
              ),
          },
          {
            path: 'admin',
            loadChildren: () =>
              import('./components/admin/admin.routes').then(
                (m) => m.adminRoutes,
              ),
          },
          {
            path: 'agent',
            loadChildren: () =>
              import('./components/agent/agent.routes').then(
                (m) => m.agentRoutes,
              ),
          },
          {
            path: 'teamlead',
            loadChildren: () =>
              import('./components/teamlead/teamlead.routes').then(
                (m) => m.teamleadRoutes,
              ),
          },
          {
            path: 'agency',
            loadChildren: () =>
              import('./components/agency/agency.routes').then(
                (m) => m.agencyRoutes,
              ),
          },
          {
            path: 'calls',
            loadChildren: () =>
              import('./components/calls/calls.routes').then((m) => m.callsRoutes),
          },
          {
            path: 'lead',
            loadChildren: () =>
              import('./components/lead/lead.routes').then((m) => m.leadRoutes),
          },
          {
            path: 'monitoring',
            loadChildren: () =>
              import('./components/monitoring/monitoring.routes').then(
                (m) => m.monitoringRoutes,
              ),
          },
          {
            path: 'campaigns',
            loadChildren: () =>
              import('./components/campaigns/campaigns.routes').then(
                (m) => m.campaignsRoutes,
              ),
          },
          {
            path: 'numbers',
            loadChildren: () =>
              import('./components/numbers/numbers.routes').then(
                (m) => m.numbersRoutes,
              ),
          },
          {
            path: 'queues',
            loadChildren: () =>
              import('./components/queues/queues.routes').then(
                (m) => m.queuesRoutes,
              ),
          },
          {
            path: 'dispo-status',
            loadChildren: () =>
              import('./components/dispo-status/dispo-status.routes').then(
                (m) => m.dispoStatusRoutes,
              ),
          },
          {
            path: 'scripts',
            loadChildren: () =>
              import('./components/scripts/scripts.routes').then(
                (m) => m.scriptsRoutes,
              ),
          },
          {
            path: 'statistics',
            loadChildren: () =>
              import('./components/statistics/statistics.routes').then(
                (m) => m.statisticsRoutes,
              ),
          },
          {
            path: ':agencyId',
            children: [
              {
                path: 'agency',
                loadChildren: () =>
                  import('./components/agency/agency.routes').then(
                    (m) => m.agencyRoutes,
                  ),
              },
              {
                path: 'campaigns',
                loadChildren: () =>
                  import('./components/campaigns/campaigns.routes').then(
                    (m) => m.campaignsRoutes,
                  ),
              },
              {
                path: 'lead',
                loadChildren: () =>
                  import('./components/lead/lead.routes').then((m) => m.leadRoutes),
              },
              {
                path: 'numbers',
                loadChildren: () =>
                  import('./components/numbers/numbers.routes').then(
                    (m) => m.numbersRoutes,
                  ),
              },
              {
                path: 'queues',
                loadChildren: () =>
                  import('./components/queues/queues.routes').then(
                    (m) => m.queuesRoutes,
                  ),
              },
              {
                path: 'dispo-status',
                loadChildren: () =>
                  import('./components/dispo-status/dispo-status.routes').then(
                    (m) => m.dispoStatusRoutes,
                  ),
              },
              {
                path: 'scripts',
                loadChildren: () =>
                  import('./components/scripts/scripts.routes').then(
                    (m) => m.scriptsRoutes,
                  ),
              },
            ]
          },
        ],
      },

    ],
  },
];